import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';
import auth from './auth';

Vue.use(VueRouter);

const routes = [
    auth,
    appRoutes
];

if (process.env.NODE_ENV !== 'production') {
    const docsRoutes = require('lego-framework/src/router/docs-layout');
    routes.push(docsRoutes.default);
}

const router = new VueRouter({
    mode : 'history',
    base : process.env.BASE_URL,
    routes
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
