import Button from 'lego-framework/src/components/Button';

const EnableButton = {
    extends : Button,
    name    : 'enable-button',
    props   : {
        icon         : { type : String, default : 'fa fa-check' },
        color        : { type : String, default : 'primary' },
        borderRadius : { type : String, default : 'full' }
    }
};

const DisableButton = {
    extends : Button,
    name    : 'disable-button',
    props   : {
        icon         : { type : String, default : 'fa fa-stop' },
        color        : { type : String, default : 'danger' },
        borderRadius : { type : String, default : 'full' }
    }
};

const EditIconButton = {
    extends : Button,
    name    : 'edit-icon-button',
    props   : {
        icon         : { type : String, default : 'fa fa-pencil' },
        color        : { type : String, default : 'primary' },
        borderRadius : { type : String, default : 'full' }
    }
};

export default {
    EnableButton,
    DisableButton,
    EditIconButton
};
