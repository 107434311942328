const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    baseUrl,
    auth : {
        send_otp       : baseUrl + 'auth/send-otp',
        NewUser        : baseUrl + 'auth/send-otp-register',
        NewUser_verify : baseUrl + 'auth/register',
        login          : baseUrl + 'auth/login',
        status         : baseUrl + 'auth/status/',

        // old //
        OTPVerification : baseUrl + 'users/verify-otp/',
        logout          : baseUrl + 'auth/logout/'
    },
    categories : {
        list          : baseUrl + 'category/list',
        add           : baseUrl + 'category/create',
        edit          : baseUrl + 'category/update',
        delete        : baseUrl + 'category/delete',
        enableDisable : baseUrl + 'category/enable-disable'
    },
    products : {
        list               : baseUrl + 'product/list',
        add                : baseUrl + 'product/create',
        edit               : baseUrl + 'product/update/',
        delete             : baseUrl + 'product/delete/',
        enableDisable      : baseUrl + 'product/enable-disable',
        importExcel        : baseUrl + 'product/import-excel',
        stockEnableDisable : baseUrl + 'products/update-stock/' // not update //
    },
    orders : {
        list      : baseUrl + 'order/list/',
        details   : baseUrl + 'order/details/',
        status    : baseUrl + 'order/status-update/',
        cancel    : baseUrl + 'order/cancel-order/',
        orderList : baseUrl + 'order/order-list/'
    },
    users : {
        list      : baseUrl + 'order/customers/',
        details   : baseUrl + 'order/customer-order-details/',
        add       : baseUrl + 'users/add/',
        preOrder  : baseUrl + 'order/order-table/',
        Customers : baseUrl + 'order/customer-items/'
    },
    dashboard : {
        todaySalesData : baseUrl + 'dashboard/today-sales-details',
        TopProducts    : baseUrl + 'dashboard/today-sale-products',
        list           : baseUrl + 'dashboard/daily-report'
    },
    select : {
        CategorySelect : baseUrl + 'masterSelect/category-select'
    }
};
